export default function LogoIcon(props: React.ComponentProps<"svg">) {
  return (
    <svg viewBox="0 0 76 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="size-full" {...props}>
      <path
        d="M71.0689 8.99998H67.7109V0.272705H71.0348C71.9325 0.272705 72.7081 0.447421 73.3615 0.796853C74.0178 1.14344 74.5234 1.64344 74.8786 2.29685C75.2365 2.94742 75.4155 3.72725 75.4155 4.63634C75.4155 5.54543 75.2379 6.32668 74.8828 6.98009C74.5277 7.63066 74.0249 8.13066 73.3743 8.48009C72.7237 8.82668 71.9553 8.99998 71.0689 8.99998ZM70.0803 6.98861H70.9837C71.4155 6.98861 71.7834 6.91901 72.0874 6.77981C72.3942 6.6406 72.6271 6.40055 72.7862 6.05964C72.9482 5.71873 73.0291 5.2443 73.0291 4.63634C73.0291 4.02839 72.9467 3.55396 72.782 3.21305C72.62 2.87214 72.3814 2.63208 72.066 2.49288C71.7535 2.35367 71.37 2.28407 70.9155 2.28407H70.0803V6.98861Z"
        fill="currentColor"
      />
      <path
        d="M59.6953 8.99998V0.272705H63.4624C64.1101 0.272705 64.6768 0.390603 65.1626 0.626398C65.6484 0.862194 66.0263 1.20168 66.2962 1.64486C66.5661 2.08805 66.701 2.6193 66.701 3.23861C66.701 3.86361 66.5618 4.3906 66.2834 4.81958C66.0078 5.24856 65.62 5.57242 65.12 5.79117C64.6229 6.00992 64.0419 6.1193 63.3771 6.1193H61.1271V4.27839H62.8999C63.1783 4.27839 63.4155 4.2443 63.6115 4.17611C63.8104 4.10509 63.9624 3.99288 64.0675 3.83947C64.1754 3.68606 64.2294 3.48577 64.2294 3.23861C64.2294 2.98861 64.1754 2.78549 64.0675 2.62924C63.9624 2.47015 63.8104 2.35367 63.6115 2.27981C63.4155 2.2031 63.1783 2.16475 62.8999 2.16475H62.0646V8.99998H59.6953ZM64.809 4.9943L66.9908 8.99998H64.4169L62.2862 4.9943H64.809Z"
        fill="currentColor"
      />
      <path
        d="M52.4113 8.99998H49.8545L52.7352 0.272705H55.9738L58.8545 8.99998H56.2977L54.3886 2.67611H54.3204L52.4113 8.99998ZM51.934 5.5568H56.7409V7.32952H51.934V5.5568Z"
        fill="currentColor"
      />
      <path
        d="M49.599 4.6364C49.599 5.60799 49.4101 6.42759 49.0322 7.0952C48.6544 7.75998 48.1444 8.26424 47.5024 8.60799C46.8604 8.9489 46.1444 9.11935 45.3547 9.11935C44.5592 9.11935 43.8405 8.94748 43.1984 8.60373C42.5592 8.25714 42.0507 7.75145 41.6729 7.08668C41.2979 6.41907 41.1104 5.60231 41.1104 4.6364C41.1104 3.66481 41.2979 2.84662 41.6729 2.18185C42.0507 1.51424 42.5592 1.00998 43.1984 0.669067C43.8405 0.325317 44.5592 0.153442 45.3547 0.153442C46.1444 0.153442 46.8604 0.325317 47.5024 0.669067C48.1444 1.00998 48.6544 1.51424 49.0322 2.18185C49.4101 2.84662 49.599 3.66481 49.599 4.6364ZM47.1615 4.6364C47.1615 4.11367 47.0919 3.67333 46.9527 3.31537C46.8163 2.95458 46.6132 2.68185 46.3433 2.49719C46.0763 2.30969 45.7467 2.21594 45.3547 2.21594C44.9626 2.21594 44.6317 2.30969 44.3618 2.49719C44.0947 2.68185 43.8916 2.95458 43.7524 3.31537C43.616 3.67333 43.5479 4.11367 43.5479 4.6364C43.5479 5.15912 43.616 5.60089 43.7524 5.96168C43.8916 6.31964 44.0947 6.59236 44.3618 6.77986C44.6317 6.96452 44.9626 7.05685 45.3547 7.05685C45.7467 7.05685 46.0763 6.96452 46.3433 6.77986C46.6132 6.59236 46.8163 6.31964 46.9527 5.96168C47.0919 5.60089 47.1615 5.15912 47.1615 4.6364Z"
        fill="currentColor"
      />
      <path
        d="M33.1289 8.99998V0.272705H36.913C37.5778 0.272705 38.136 0.362194 38.5877 0.541171C39.0423 0.720148 39.3846 0.972989 39.6147 1.29969C39.8477 1.6264 39.9641 2.01134 39.9641 2.45452C39.9641 2.77555 39.8931 3.06816 39.7511 3.33236C39.6119 3.59657 39.4158 3.81816 39.163 3.99714C38.9102 4.17327 38.6147 4.29543 38.2766 4.36361V4.44884C38.6516 4.46305 38.9925 4.55822 39.2994 4.73435C39.6062 4.90765 39.8505 5.1477 40.0323 5.45452C40.2141 5.7585 40.305 6.11646 40.305 6.52839C40.305 7.00566 40.18 7.43038 39.93 7.80254C39.6829 8.17469 39.3306 8.46731 38.8732 8.68038C38.4158 8.89344 37.8704 8.99998 37.2369 8.99998H33.1289ZM35.4982 7.10793H36.6062C37.0039 7.10793 37.3022 7.03407 37.5011 6.88634C37.6999 6.73577 37.7994 6.51418 37.7994 6.22157C37.7994 6.01702 37.7525 5.84373 37.6587 5.70168C37.565 5.55964 37.4315 5.45168 37.2582 5.37782C37.0877 5.30396 36.8817 5.26702 36.6403 5.26702H35.4982V7.10793ZM35.4982 3.80111H36.4698C36.6772 3.80111 36.8604 3.76844 37.0195 3.7031C37.1786 3.63776 37.3022 3.54401 37.3903 3.42185C37.4812 3.29685 37.5266 3.14486 37.5266 2.96589C37.5266 2.696 37.43 2.49003 37.2369 2.34799C37.0437 2.2031 36.7994 2.13066 36.5039 2.13066H35.4982V3.80111Z"
        fill="currentColor"
      />
      <path
        d="M23.5957 0.272705H26.2377L27.9423 3.81816H28.0105L29.715 0.272705H32.3571L29.1525 6.25566V8.99998H26.8002V6.25566L23.5957 0.272705Z"
        fill="currentColor"
      />
      <path
        d="M17.5597 8.99998H15.0029L17.8836 0.272705H21.1222L24.0029 8.99998H21.4461L19.537 2.67611H19.4688L17.5597 8.99998ZM17.0825 5.5568H21.8893V7.32952H17.0825V5.5568Z"
        fill="currentColor"
      />
      <path d="M8.4375 8.99998V0.272705H10.8068V7.09089H14.3352V8.99998H8.4375Z" fill="currentColor" />
      <path
        d="M0.5625 8.99998V0.272705H4.32955C4.97727 0.272705 5.54403 0.400546 6.02983 0.656228C6.51563 0.91191 6.89347 1.27128 7.16335 1.73435C7.43324 2.19742 7.56818 2.73861 7.56818 3.35793C7.56818 3.98293 7.42898 4.52413 7.15057 4.98151C6.875 5.4389 6.48722 5.79117 5.98722 6.03833C5.49006 6.28549 4.90909 6.40907 4.24432 6.40907H1.99432V4.56816H3.76705C4.04545 4.56816 4.28267 4.51986 4.47869 4.42327C4.67756 4.32384 4.82955 4.18322 4.93466 4.0014C5.04261 3.81958 5.09659 3.60509 5.09659 3.35793C5.09659 3.10793 5.04261 2.89486 4.93466 2.71873C4.82955 2.53975 4.67756 2.40339 4.47869 2.30964C4.28267 2.21305 4.04545 2.16475 3.76705 2.16475H2.93182V8.99998H0.5625Z"
        fill="currentColor"
      />
    </svg>
  )
}
