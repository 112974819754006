"use client"

import { startTransition, useEffect } from "react"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/navigation"
import * as Sentry from "@sentry/nextjs"
import { Home, RotateCcw } from "lucide-react"

import { Button } from "@/components/ui/button"
import { PlayboardLogo } from "@/components/playboard-logo"

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  const router = useRouter()

  function refreshAndReset() {
    startTransition(() => {
      router.refresh()
      reset()
    })
  }

  useEffect(() => {
    Sentry.captureException(error)
    console.error(error)
  }, [error])

  return (
    <div className="h-dvh p-4">
      <PlayboardLogo />
      <div className="flex h-full flex-col items-center justify-center gap-6">
        <Image src={"/images/users/Sorry.svg"} alt="Error" width={320} height={320} priority />
        <div className="flex flex-col gap-1">
          <span className=" text-center text-5xl font-semibold leading-display">ขออภัย</span>
          <span className=" text-pretty text-center text-xl font-semibold leading-display">
            เกิดข้อผิดพลาดบางอย่าง ทีมของเราทราบปัญหานี้แล้ว และกำลังแก้ไข
          </span>
        </div>
        <span className="text-md text-error-primary">รหัสอ้างอิง : #{error.digest}</span>
        <div className="flex items-center gap-3">
          <Button type="button" asChild variant={"secondary"}>
            <Link href="/">
              <Home size={20} />
              กลับไปหน้าหลัก
            </Link>
          </Button>
          <Button type="button" onClick={refreshAndReset}>
            <RotateCcw size={20} />
            ลองอีกครั้ง
          </Button>
        </div>
      </div>
    </div>
  )
}
