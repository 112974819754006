import { cn } from "@/lib/utils"

import LogoIcon from "@/components/logo"

export function PlayboardLogo({
  className,
  backgroundColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  backgroundColor?: string
}) {
  return (
    <div className={cn("flex w-full gap-4", className)} {...rest}>
      <div
        className="flex aspect-[76/10] w-[120px] items-center"
        style={{
          backgroundColor: backgroundColor ?? "transparent",
        }}
      >
        <LogoIcon />
      </div>
    </div>
  )
}
